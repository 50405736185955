import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

export default ({ title, slug, cover, entryPrefix = "story" }) => (
  <Link to={`/${entryPrefix}/${slug}`} id="grid-story">
    <div className="grid-content">
      {cover && <Img fluid={cover.image.childImageSharp.fluid} className="grid-image" />}
      <div className="grid-text">
        <div className="grid-title">{title}</div>
      </div>
    </div>
  </Link>
)
