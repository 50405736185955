import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Grid from "../components/grid/Grid"
import GridItem from "../components/grid/schema/GridItem"

import "./index.styl"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      alloy {
        pages {
          _id
          path
          grid {
            gridSize
            items {
              itemId
              width
              height
              schema
              item {
                _id
                ... on Alloy_Page {
                  _id
                  title
                  path
                }
                ... on Alloy_Product {
                  _id
                  name
                  slug
                  price
                  quantity
                  images {
                    link
                    image {
                      childImageSharp {
                        fluid(maxWidth: 1200) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
                ... on Alloy_Story {
                  _id
                  title
                  slug
                  cover {
                    link
                    image {
                      childImageSharp {
                        fluid(maxWidth: 1200) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                }
                ... on Alloy_Image {
                  _id
                  link
                  image {
                    childImageSharp {
                      fluid(maxWidth: 2400) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const { alloy } = data
  const { pages } = alloy
  const home = pages.find(page => !page.path)

  return (
    <div id="home" className="main-page">
      {home && home.grid && (
        <Grid
          items={home.grid.items}
          grid={home.grid}
          render={item => <GridItem {...item} />}
        />
      )}
    </div>
  )
}
