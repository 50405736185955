import React from "react"

import ProductGrid from "./ProductGrid"
import StoryGrid from "./StoryGrid"
import PageGrid from "./PageGrid"
import ImageGrid from "./ImageGrid"

export default ({ item, schema })  => {

  if (!item) return <div>Not existing or not visible</div>

  switch (schema) {
    case "product":
      return <ProductGrid {...item} />
    case "story":
      return <StoryGrid {...item} />
    case "page":
      return <PageGrid {...item} />
    case "_image":
      return <ImageGrid {...item} />
    default:
      return <div>Not defined Grid</div>
  }
}
