import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

export default ({ name, images, price, quantity, earmarks, slug, entryPrefix = "produkt" }) => (
  <Link to={`/${entryPrefix}/${slug}`} id="grid-product">
    <div className="grid-content">
      {images && images.length && <Img fluid={images[0].image.childImageSharp.fluid} objectFit="contain" className="grid-image"/>}
      {earmarks && earmarks.length ? <div className="grid-earmarks">{earmarks[0]}</div> : ''}
      <div className="grid-title">{name}</div>
    </div>
    <div className="grid-cover">
      <div className="grid-cover-text">
        {name && <h2 className="grid-cover-title">{name}</h2>}
        {price && (
          <p className="grid-cover-subtitle">
            {quantity && `${quantity}: `}
            €&nbsp;{parseFloat(price).toFixed(2).replace('.', ',')}
          </p>
        )}
      </div>
    </div>
  </Link>
)
