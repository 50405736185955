import React, { useState, useEffect } from "react"

import "./Grid.styl"

const getGrid = grid => {
  const { items, gridSize } = grid
  const gridItem = []

  let x = 0
  let y = 0

  const setNextSpace = width => {
    if (y + width > gridSize) {
      // no space anymore in this row
      x += 1
      y = 0
    }

    if (!gridItem[x]) return

    if (!gridItem[x][y]) {
      // on this place no id is set
      // check next fields
      let check = true
      for (let i = 1; i < width; i++) {
        if (gridItem[x][y + 1]) check = false
      }
      if (check) return
    }
    y += 1

    setNextSpace(width)
  }

  const setItems = (itemId, width, height) => {
    if (!gridItem[x]) gridItem[x] = []
    gridItem[x][y] = itemId

    // fill other fields
    // width
    for (let i = 0; i < width; i++) {
      gridItem[x][y + i] = itemId

      // height
      for (let j = 0; j < height; j++) {
        if (!gridItem[x + j]) gridItem[x + 1] = []
        gridItem[x + j][y + i] = itemId
      }
    }
  }

  items.forEach(({ itemId, width, height }) => {
    setNextSpace(width)
    setItems(itemId, width, height)

    y += width
    if (y > gridSize - 1) {
      x += 1
      y = 0
    }
  })

  return gridItem
}

export default ({ items = [], render = () => {}, grid }) => {
  const [isDesktop, setIsDesktop] = useState(true) // it will start with desktop version
  const heightProportion = 4 / 5
  const gridWidth = 75
  const scrollBarBlockView = 15 / 4 // px
  const { gridSize = 3 } = grid || {}
  const gridItems = grid ? getGrid(grid) : []

  useEffect(() => {
    // find out if Desktop
    const windowDesktop = window.matchMedia("(min-width: 1000px)")
    const isDesktop = ({ matches }) => setIsDesktop(matches)

    isDesktop(windowDesktop)
    windowDesktop.addListener(isDesktop)
  })

  return (
    <div className="grid">
      <div 
        className="container"
        style={{
          minHeight: isDesktop
            ? `calc((25vw - ${grid ? 0 : scrollBarBlockView}px) * ${heightProportion} * ${grid ? gridItems.length : Math.ceil(items.length / 3)})`
            : `calc(50vw * ${heightProportion} * ${Math.ceil(items.length / 2)})`,
        }}
      >
        {items.map((item, index) => {
          let gridStyle = {}
          if (gridItems.length) {
            const { itemId, width, height } = item

            let x = gridItems.findIndex(item => item.includes(itemId))
            let y = gridItems[x].findIndex(item => item === itemId)

            gridStyle = {
              width: `calc(${(gridWidth * width) / gridSize}vw)`,
              height: `calc((${(gridWidth * height) / gridSize}vw) * ${heightProportion})`,

              left: `calc(${gridWidth / gridSize}vw * ${y})`,
              top: isDesktop
                ? `calc(${gridWidth / gridSize}vw * ${x} * ${heightProportion})`
                : `calc(${(gridWidth / gridSize) * 2}vw * ${Math.floor(index / 2)} * ${heightProportion})`,
            }
          } else {
            gridStyle = {
              width: `calc(25vw)`,
              height: `calc((25vw - ${scrollBarBlockView}px) * ${heightProportion})`,

              left: `calc(25vw * ${index % gridSize})`,
              top: isDesktop
                ? `calc((25vw - ${scrollBarBlockView}px) * ${heightProportion} * ${Math.floor(index / 3)})`
                : `calc(50vw * ${heightProportion} * ${Math.floor(index / 2)})`,
            }
          }

          return  (
            <div className="grid-item" key={index} style={gridStyle}>
              {render(item)}
            </div>
          )
        })}
      </div>
    </div>
  )
}
